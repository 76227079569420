import key from "../config/index";
export const url = {
    home: {
        getSpot: `${key.API_URL}/reports/dashboard`,
        getDerivative: `${key.API_URL}/reports/`

    }
}

export const apitrigger = async (url, method, isauthenticate, data) => {
    const payload = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
    };
    if (isauthenticate) {
        payload.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if (method !== "GET" && data) {
        payload.body = JSON.stringify({ data: data })
    }
    let response = await fetch(url, payload);
    response = await response.json();
    // response = decryptData(response)
    return response;
}
// export const downloadApi = async (url, payload) => {
//     let response = await fetch(url, payload);
//     response = await response.json();
//     response = decryptData(response)
//     return response;
// }
