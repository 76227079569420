import axios from "../config/axios";
import * as XLSX from 'xlsx';
export const Addoffertaghook = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/p2papiadmin/add-offertag`,
            'data': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const Editoffertaghook = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/p2papiadmin/edit-offertag`,
            'data': data
        });

        return respData;
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const Getoffertaghook = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/p2papiadmin/get-offertag`,
            'params': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const Getofferhistoryhook = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/p2papiadmin/get-offer-history`,
            'params': data
        });
        if (data.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            respData.data.forEach(row => {
                csvContent += `${row.toString()}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", 'Offer history.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            let jsonData = respData.data;
            const [date, createrId, orderId, currency, type, preferedCurrency, priceType, timeLimit, minBuy, maxBuy, offerMargin] = jsonData[0];
            jsonData.shift();
            console.log(date, createrId, orderId, currency, type, preferedCurrency, priceType, timeLimit, minBuy, maxBuy, offerMargin,"afetr shiting")
            jsonData = jsonData.map((item) => {
                return {
                    [date]: item[0],
                    [createrId]: item[1],
                    [orderId]: item[2],
                    [currency]: item[3],
                    [type]: item[4],
                    [preferedCurrency]: item[5],
                    [priceType]: item[6],
                    [timeLimit]: item[7],
                    [minBuy]: item[8],
                    [maxBuy]: item[9],
                    [offerMargin]: item[10]
                }
            })
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Offer_History.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const Gettradehistoryhook = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/p2papiadmin/get-trade-history`,
            'params': data
        });

        if (data.export == 'csv') {
            const url = window.URL.createObjectURL(new Blob([respData.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'currency List.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (data.export == 'xls') {
            const url = window.URL.createObjectURL(new Blob([respData.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'currency List.xls');
            document.body.appendChild(link);
            link.click();
        }

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const Getpaymenttypehook = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/p2papiadmin/get-paymenttypes`,
            'params': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const Addpaymenttypeshooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/p2papiadmin/add-paymenttypes`,
            'data': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const Editpaymenttypehooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/p2papiadmin/edit-paymenttypes`,
            'data': data
        });

        return respData;
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const Addownerwallethooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/p2papiadmin/updatewallet`,
            'data': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const AddDemoVideoLink = async (id,data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/future/updatelink/${id}`,
            'data': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message,
            error: err?.response?.data?.errors
        }
    }
}

export const GetDemoVideoLink = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/future/getdemoLink`,
            'params': data
        });
        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const Getownerwallethooks = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/p2papiadmin/getownerwallet`,
            'params': data
        });

        return respData
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}