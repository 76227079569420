// import config
import axios from '../config/axios';
import * as XLSX from 'xlsx';
export const currencyList = async (reqData) => {
    try {
        const respData = await axios({
            'url': `/adminapi/currency`,
            'method': 'get',
            'params': reqData
        })
        if (reqData.export == 'csv') {
            let csvContent = "data:text/csv;charset=utf-8,";
            respData.data.forEach(row => {
                csvContent += `${row.toString()}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download",'currencyList.csv');
            document.body.appendChild(link);
            link.click();
        }

        if (reqData.export == 'xls') {
            let jsonData = respData.data;
            const [name, type, coin, status] = jsonData[0];
            jsonData.shift();
            jsonData = jsonData.map((item) => {
                return { [name]: item[0], [type]: item[1], [coin]: item[2], [status]: item[3] }
            })
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['Currency list'] };
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'currency_list.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const addCurrency = async (reqData) => {
    try {
        const respData = await axios({
            'url': `/adminapi/currency`,
            'method': 'post',
            'data': reqData
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const updateCurrency = async (reqData) => {
    try {
        const respData = await axios({
            'url': `/adminapi/currency`,
            'method': 'put',
            'data': reqData
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const getCurrency = async () => {
    try {
        const respData = await axios({
            'url': `/adminapi/getCurrency`,
            'method': 'get',
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
        }
    }
}