// import package
import React, { Component } from 'react';
import { Card, Button } from "react-bootstrap";
import classnames from "classnames";
import { AddDemoVideoLink,GetDemoVideoLink } from '../../actions/P2PCreateaction';
import { toastAlert } from '../../lib/toastAlert';
// import { encryptString } from '../../../../Backend/lib/cryptoJS';


// import action

//import lib

class DemoVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            link:"",
            privatekey : "" , 
            walletaddress : "",
            id:""
        }
        
    }
    componentDidMount(){
        this.fetchdata();
    }

    async fetchdata(){
        var result = await GetDemoVideoLink();
        this.setState({link : result?.data?.data?.demolink?.futureLink ,id:result?.data?.data?._id});
    }

    async handleSubmit(){
        const payload = {
            link : this?.state?.link,
        }
        if(!payload?.link){
           return toastAlert("error" , "Link is required.");
        }
        const result = await AddDemoVideoLink(this?.state.id,payload);
        this.fetchdata();
        if(result?.status === 200){
            toastAlert("success" , "Updated successfully!..");
        }
    }

   

    render() {
        const { errors } = this.state;

        return (
            <Card>
                <Card.Header><p className="text-white mb-0"><b>Demo video</b></p></Card.Header>
                <Card.Body>
                    <div className="row mt-2 align-items-center">
                        <div className="col-md-3">
                            <label htmlFor="currencyName">Demo video link</label>
                        </div>
                        <div className="col-md-9">
                        <input
                                
                                name="walletaddress"
                                error={errors.walletaddress}
                                id="walletaddress"
                                type="text"
                                value = {this?.state?.link}
                                className={classnames("form-control", {
                                    invalid: errors.walletaddress
                                })}
                                onChange = {(e) => this.setState({link : e.target.value})}
                            />

                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={() =>this.handleSubmit()}>Submit</Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default DemoVideo;