import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import action
import { faqCategoryEdit } from "../../../actions/faqActions";

// import lib
import { toastAlert } from "../../../lib/toastAlert"
import { editPair } from "../../../actions/walletAction";
import { encryptData, decryptData } from "../../../utils/common_function";

const initialFormValue = {
  id: "",
  firstCurrencySymbol: "",
  secondCurrencySymbol: "",
  botstatus: "",
  firstCurrency: "",
  secondCurrency: ""
};

class EditFuturePair extends React.Component {
  constructor() {
    super();
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    if (record) {
      const editStateValue = {
        id: record._id,
        firstCurrencySymbol: record?.firstCurrencySymbol,
        secondCurrencySymbol: record?.secondCurrencySymbol,
        status: record?.status,
        description: record?.description,
        precision: record?.PairFloatDigit,
        markPrice: record?.markPrice,
        maxQuantity: record?.maxQuantity,
        minQuantity: record?.minQuantity,
        maintananceMargin: record?.maintananceMargin,
        initialMargin: record?.initialMargin,
        leaverage: record?.leverage,
        firstCurrencyDecimal: record?.firstCurrencyDecimal,
        secondCurrencyDecimal: record?.secondCurrencyDecimal,
        openFee: record?.openFee,
        closeFee: record?.closeFee,
        low: record?.low,
        high: record?.high,
        askPrice: record?.ask,
        bidPrice: record?.bid,
        last: record?.last,
        high: record?.high,
        firstVolume: record?.firstVolume,
        secondVolume: record?.secondVolume,
        changePrice: record?.changePrice,
        change: record?.change,
        minPricePercentage: record?.minPrice,
        maxPricePercentage: record?.maxPrice,
        apiUrl: record?.priceUrl,
        priceKey: record?.priceKey,
        isStable: record?.isStable,
        executionFee: record?.executionFee,
        botstatus: record?.botstatus
      }
      if (record?.apiKey) {
        editStateValue.apiKey = decryptData(record?.apiKey)
      }
      this.setState({
        formValue: editStateValue
      });
    }
  }
  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
    this.setState({ errors: "" });
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
  };

  handleDropChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // value = JSON.parse(value);
    // const { symbol } = value
    const spiltResult = value.split("_")
    value = spiltResult[0]
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    formData.isStable = JSON.parse(spiltResult[1])
    this.setState({ formValue: formData });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { fetchData } = this.props;
      const { formValue } = this.state;
      let reqData = formValue;
      const payload = {
        firstCurrencySymbol: formValue.firstCurrencySymbol ? formValue.firstCurrencySymbol : this.props?.record?.firstCurrencySymbol,
        secondCurrencySymbol: formValue.secondCurrencySymbol ? formValue.secondCurrencySymbol : this.props?.record?.secondCurrencySymbol,
        status: formValue.status ? formValue.status : this.props?.record?.status,
        type: "future",
        description: formValue.description,
        PairFloatDigit: formValue.precision,
        markPrice: formValue.markPrice,
        maxQuantity: formValue.maxQuantity,
        minQuantity: formValue.minQuantity,
        maintananceMargin: formValue.maintananceMargin,
        initialMargin: formValue.initialMargin,
        openFee: formValue.openFee,
        closeFee: formValue.closeFee,
        last: formValue.last,
        firstCurrencyDecimal: formValue.firstCurrencyDecimal,
        secondCurrencyDecimal: formValue.secondCurrencyDecimal,
        leverage: formValue.leaverage,
        minPrice: formValue.minPricePercentage,
        maxPrice: formValue.maxPricePercentage,
        priceUrl: formValue?.apiUrl,
        executionFee: formValue?.executionFee,
        botstatus: formValue?.botstatus,
        // apiKey: encryptData(formValue?.apiKey),
        isStable: formValue?.isStable
      }
      if (formValue?.apiKey) {
        payload.apiKey = encryptData(formValue?.apiKey)
      }
      // const payload = {
      //   firstCurrencySymbol: formValue.firstCurrencySymbol ? formValue.firstCurrencySymbol : this.props?.record?.firstCurrencySymbol,
      //   secondCurrencySymbol: formValue.secondCurrencySymbol ? formValue.secondCurrencySymbol : this.props?.record?.secondCurrencySymbol,
      //   status: formValue.status ? formValue.status : this.props?.record?.status,
      //   type: "derivative",
      // }
      payload.tikerRoot = payload.firstCurrencySymbol + payload.secondCurrencySymbol
      if (!payload.firstCurrencySymbol) {
        return toastAlert("error", "Base Currency may not be empty", "addFaqCategory");
      }
      if (!payload.secondCurrencySymbol) {
        return toastAlert("error", "Quote Currency may not be empty", "addFaqCategory");
      }
      if (!payload.isStable) {
        if (payload.firstCurrencySymbol === payload.secondCurrencySymbol) {
          return toastAlert("error", "Base and Quote are not allowed as same", "addFaqCategory");
        }
        if (Number(payload.minQuantity) >= Number(payload.maxQuantity)) {
          return toastAlert("error", "Minimum quantity must be lesser than maximum quanity.", "addFaqCategory");
        } if (!payload.firstCurrencyDecimal) {
          return toastAlert("error", "Base currency decimal may not be empty.", "addFaqCategory");
        }
        if (!payload.secondCurrencyDecimal) {
          return toastAlert("error", "Quote currency decimal may not be empty.", "addFaqCategory");
        }
        if (!payload.openFee) {
          return toastAlert("error", "Open fee may not be empty.", "addFaqCategory");
        }
        if (!payload.closeFee) {
          return toastAlert("error", "Close fee may not be empty.", "addFaqCategory");
        }
        if (!payload.initialMargin) {
          return toastAlert("error", "Initial margin may not be empty.", "addFaqCategory");
        }
        if (!payload.maintananceMargin) {
          return toastAlert("error", "Maintanance margin may not be empty.", "addFaqCategory");
        }
        if (!payload.leverage) {
          return toastAlert("error", "Leaverage may not be empty.", "addFaqCategory");
        }
        if (!payload.minPrice) {
          return toastAlert("error", "Minimum price percentage  may not be empty.", "addFaqCategory");
        }
        if (!payload.maxPrice) {
          return toastAlert("error", "Maximium price percentage may not be empty.", "addFaqCategory");
        }
        if (payload.minPrice && Number(payload.minPrice) <= 0 || payload.minPrice && Number(payload.minPrice) > 100) {
          return toastAlert("error", "Minimum price percentage  must be in 1 to 100", "addFaqCategory");
        }
        if (payload.maxPrice && Number(payload.maxPrice) <= 0 || payload.maxPrice && Number(payload.maxPrice) > 100) {
          return toastAlert("error", "Maximum price percentage  must be in 1 to 100", "addFaqCategory");
        }
      }
      if (payload.isStable) {
        if (!payload.priceUrl) {
          return toastAlert("error", "API URL not be empty.", "addFaqCategory");
        }
        if (!payload.apiKey) {
          return toastAlert("error", "API key not be empty.", "addFaqCategory");
        }
      }
      if (!payload.botstatus) {
        return toastAlert("error", "Please select a Bot status.", "addFaqCategory");
      }
      const result = await editPair(this.props?.record?._id, payload);
      if (result && result.result && result.result.data && result.result.data.statusCode && result.result.data.statusCode === 200) {
        return toastAlert("error", result.result.data.message, "addFaqCategory");
      }
      window.location.reload()
    } catch (err) { }
  };

  render() {
    const { errors } = this.state;
    const { status, firstCurrencySymbol, secondCurrencySymbol, description, minQuantity, maxQuantity, askPrice, precision, markPrice, openFee,
      closeFee, maintananceMargin, firstCurrencyDecimal, secondCurrencyDecimal, initialMargin, maxPricePercentage, minPricePercentage,
      bidPrice, low, high, last, change, changePrice, firstVolume, secondVolume, leaverage, apiUrl, priceKey, apiKey, isStable, executionFee, botstatus
    } = this.state.formValue;
    const { isShow, record, records, fullList } = this.props;
    return (
      <div>
        <Modal
          show={isShow}
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          size="md"
          centered
        // scrollable={true}
        >
          <Modal.Header closeButton>
            <h4 className="modal-title">Edit Margin Pair</h4>
          </Modal.Header>
          <Modal.Body className="derivative__modalBody">
            <form noValidate onSubmit={this.handleSubmit} id="add-faq">
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="name" className="addingPair__cmnLabel">Base Currency</label>
                </div>
                <div className="col-md-8">
                  <Form.Control
                    as="select"
                    custom
                    name={"firstCurrencySymbol"}
                    value={firstCurrencySymbol ? firstCurrencySymbol : record?.firstCurrencySymbol}
                    // value={firstCurrencySymbol ? `${firstCurrencySymbol}_${isStable ? isStable : false}` : `${record?.symbol}_${record?.isStable ? record?.isStable : false}`}
                    //value="BTC"
                    // onChange={this.handleDropChange}>{
                    //   records && records.length && records.map((item) => {
                    //     return (<option value={`${item.symbol}_${item.isStable ? item.isStable : false}`} >{item.symbol}</option>)
                    //   })
                    // }
                    onChange={this.handleChange}>{
                      records && records.length && records.map((item) => {
                        return (<option value={item.symbol}>{item.symbol}</option>)
                      })
                    }
                  </Form.Control>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="name" className="addingPair__cmnLabel">Quote Currency</label>
                </div>

                <div className="col-md-8">
                  <Form.Control
                    as="select"
                    custom
                    name={"secondCurrencySymbol"}
                    // value={secondCurrencySymbol}
                    // value={secondCurrencySymbol ? secondCurrencySymbol : record?.secondCurrencySymbol}
                    // onChange={this.handleChange}>{
                    //   fullList && fullList.length && fullList.map((item) => {
                    //     return (<option value={item.symbol}>{item.symbol}</option>)
                    //   })
                    // }
                    value={secondCurrencySymbol ? `${secondCurrencySymbol}_${isStable ? isStable : false}` : `${record?.symbol}_${record?.isStable ? record?.isStable : false}`}
                    onChange={this.handleDropChange}>{
                      fullList && fullList.length && fullList.map((item) => {
                        return (<option value={`${item.symbol}_${item.isStable ? item.isStable : false}`} >{item.symbol}</option>)
                      })
                    }
                    {/* <option value={"active"}>Active</option>
                    <option value={"Inactive"}>Inactive</option> */}
                  </Form.Control>
                  {/* <span className="text-danger">{errors.name}</span> */}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Base Currency Decimal</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={firstCurrencyDecimal}
                    name={"firstCurrencyDecimal"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Quote Currency Decimal</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={secondCurrencyDecimal}
                    name={"secondCurrencyDecimal"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Open fee</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={openFee}
                    name={"openFee"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Close fee</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={closeFee}
                    name={"closeFee"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Initial Margin</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={initialMargin}
                    name={"initialMargin"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Maintanance Margin</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={maintananceMargin}
                    name="maintananceMargin"
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="question" className="addingPair__cmnLabel">Leaverage (Max)</label>
                </div>
                <div className="col-md-8">
                  <input
                    onChange={this.handleChange}
                    value={leaverage}
                    name={"leaverage"}
                    type="number"
                    error={errors.name}
                    className={classnames("form-control input__borderColor", {
                      invalid: errors.name,
                    })}
                  />
                  <span className="text-danger">{errors.name}</span>
                </div>
              </div>
              {!isStable && <div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Minimum Quantity</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={minQuantity}
                      name={"minQuantity"}
                      type="number"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Maximum Quantity</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={maxQuantity}
                      name={"maxQuantity"}
                      type="number"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                {/* <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Precision</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={precision}
                      name={"precision"}
                      type="number"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div> */}

                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Market Price</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={markPrice}
                      name={"markPrice"}
                      type="number"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Last</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={last}
                      name="last"
                      type="number"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Minimum Price Percentage</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={minPricePercentage}
                      name={"minPricePercentage"}
                      type="text"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Maximum Price Percentage</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={maxPricePercentage}
                      name={"maxPricePercentage"}
                      type="text"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
              </div>
              }
              {
                isStable && <div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label htmlFor="question" className="addingPair__cmnLabel">Price API URL</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        onChange={this.handleChange}
                        value={apiUrl}
                        name={"apiUrl"}
                        type="text"
                        error={errors.name}
                        className={classnames("form-control input__borderColor", {
                          invalid: errors.name,
                        })}
                        autoComplete="off"
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label htmlFor="question" className="addingPair__cmnLabel">API key</label>
                    </div>
                    <div className="col-md-8">
                      <input
                        onChange={this.handleChange}
                        autoComplete="off"
                        // style="display:none"
                        value={apiKey}
                        name={"apiKey"}
                        type="password"
                        error={errors.name}
                        className={classnames("form-control input__borderColor", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </div>
              }
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="name">Bot Status</label>
                </div>

                <div className="col-md-8">
                  <Form.Control
                    as="select"
                    custom
                    required
                    name={"botstatus"}
                    value={botstatus}
                    onChange={this.handleChange}
                  >
                    <option value={""}>Select</option>
                    <option value={"binance"}>Binance</option>
                    <option value={"off"}>Off</option>
                  </Form.Control>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="name" className="addingPair__cmnLabel">Status</label>
                  </div>

                  <div className="col-md-8">
                    <Form.Control
                      as="select"
                      custom
                      name={"status"}
                      value={status}
                      onChange={this.handleChange}
                    >
                      <option value={"active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </Form.Control>
                    {/* <span className="text-danger">{errors.name}</span> */}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <label htmlFor="question" className="addingPair__cmnLabel">Execution Fee</label>
                  </div>
                  <div className="col-md-8">
                    <input
                      onChange={this.handleChange}
                      value={executionFee}
                      name={"executionFee"}
                      type="text"
                      error={errors.name}
                      className={classnames("form-control input__borderColor", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleClose}
            >
              Close
            </button>
            <button
              onClick={this.handleSubmit}
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default EditFuturePair;
