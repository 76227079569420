import crypto from "crypto-js"
import key from "../../src/config/index";
export const encryptData = (data) => {
    const normalText = typeof (data) === 'string' ? data.toString() : JSON.stringify(data);
    return crypto.AES.encrypt(normalText, key.secretOrKey).toString()
}
export const decryptData = (data) => {
    const bytes = crypto.AES.decrypt(data, key.secretOrKey);
    try {
        const decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
        return decryptedData;
    }
    catch (error) {
        if (error.toString() === "SyntaxError: Unexpected end of JSON input") {
            return false;
        } else {
            return bytes.toString(crypto.enc.Utf8);
        }
    }
}

export const convertExpotoNumber = (n) => {
    if (n) {
        const sign = +n < 0 ? "-" : "", toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        const [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0 ?
            sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
            sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    else {
        return 0
    }
}